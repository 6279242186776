<template>
  <footer class="bg-black z-50" v-if="sharedState.footerContact">
    <div
      class="delimiter flex justify-between items-center text-white
      lg:py-10 lg:px-32
      md:py-10 md:px-24 md:flex-row
      py-10 px-8 flex-col"
    >
      <img
        src="@/assets/img/unlocking-white.png"
        alt="Unlocking the future"
        class="img-unlocking"
      />
      <div
        class="flex
        md:mt-0
        mt-8"
      >
        <ul
          class="block
          lg:mr-24
          md:mr-16
          mr-8"
        >
          <li class="block">
            <a href="https://www.facebook.com/TrendsLabtl" target="_blank">
              Facebook
            </a>
          </li>
          <li class="block">
            <a href="https://www.instagram.com/trendslab.tl/" target="_blank">
              Instagram
            </a>
          </li>
          <li class="block">
            <a
              href="https://www.linkedin.com/company/trendslab/"
              target="_blank"
            >
              LinkedIn
            </a>
          </li>
          <li class="block">
            <a href="https://twitter.com/trendslab_tl" target="_blank">
              Twitter
            </a>
          </li>
        </ul>
        <ul class="block">
          <li class="block">
            <router-link to="/about">
              {{ $t('footer.about') }}
            </router-link>
          </li>
          <li class="block">
            <router-link to="/home">
              {{ $t('footer.work') }}
            </router-link>
          </li>
          <li class="block">
            <router-link to="/contact">
              {{ $t('footer.contact') }}
            </router-link>
          </li>
          <li class="block">
            <router-link to="/home">
              {{ $t('footer.privacy') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  <footer class="bg-black z-50" v-if="!sharedState.footerContact">
    <div
      class="delimiter flex flex-wrap py-20
      xl:px-32
      lg:px-24
      md:px-16
      px-8"
    >
      <section
        class="text-white
        md:w-2/3
        w-full"
      >
        <p class="uppercase text-lg font-normal tracking-widest">
          {{ $t('footer.stayConnected') }}
        </p>
        <p
          class="uppercase font-light
          md:text-7xl
          text-5xl"
        >
          {{ $t('footer.contact') }}
        </p>
        <p class="mt-9">
          <span class="flex items-center font-bold">
            <img
              class="contact-icon"
              src="@/assets/img/whatsapp.png"
              alt="Whatsapp"
            />&nbsp;
            Wa: +52 5630849290 </span
          >
          contacto@trendslab.com.mx
        </p>
      </section>
      <section
        class="text-white
        md:w-1/3
        w-full mt-16"
      >
        <p class="mb-8">
          {{ $t('footer.hear') }}
        </p>
        <div class="bg-black mb-8">
          <iframe
            src="https://open.spotify.com/embed/playlist/77UCR5jrCoddyfuphcloEK"
            width="100%"
            height="80"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          >
          </iframe>
        </div>
        <a
          role="button"
          class="py-2 px-6 uppercase border border-white rounded-full"
          href="https://open.spotify.com/playlist/77UCR5jrCoddyfuphcloEK"
          target="_blank"
        >
          {{ $t('footer.followUs') }}
        </a>
      </section>
    </div>
  </footer>
  <section
    class="w-full py-10 footer-copyright
    xl:px-44
    lg:px-32
    md:px-20
    px-10"
    v-if="!sharedState.footerContact"
  >
    <div
      class="delimiter flex justify-between
      md:flex-row
      flex-col"
    >
      <p class="mb-8 md:mb-0">Trends Lab | CDMX</p>
      <p>Copyright © 2023, Trends Lab | {{ $t('footer.allRights') }}</p>
    </div>
  </section>
</template>

<script>
import { store } from '../main'

export default {
  name: 'Footer',
  data: () => ({
    sharedState: store.state,
  }),
}
</script>

<style scoped>
.footer-copyright {
  background-color: #060606;
  color: #7a7a7a;
}
.img-unlocking {
  height: 54px;
}

.hover-underline {
  position: relative;
}
.hover-underline::after {
  content: ' ';
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: center left;
  transition: transform 0.2s ease-out;
  will-change: transform;
}
.hover-underline:hover::after {
  transform: scaleX(1);
}
</style>
