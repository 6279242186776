export default {
  "home": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first Trends &<br/>Innovation Lab in Mexico"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More about us"])},
    "projectList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project List"])},
    "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We work with top brands"])},
    "trends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trends"])},
    "trendsReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trends Report"])},
    "editorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editorial"])},
    "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing"])},
    "gotoReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See previous reports"])},
    "newPhrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR <span class=\"font-semibold\">DATABASE</span> HAS A REACH OF +57M&nbsp;"])}
  },
  "reports": {
    "reports2021": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021"])}
    },
    "reports2022": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022"])}
    },
    "reports2023": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023"])}
    },
    "revolucionInformacion": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La revolución de la información"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De la ciencia ficción a la realidad"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/revolucion-informacion.pdf"])}
    },
    "volume1_2021": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March 18, 2021"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume 1"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume1.pdf"])}
    },
    "volume2_2021": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June 10, 2021"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume 2"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume2.pdf"])}
    },
    "volume3_2021": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September 12, 2021"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume 3"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume3.pdf"])}
    },
    "volume4_2021": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November 24, 2021"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume 4"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume4.pdf"])}
    },
    "volume1_2022": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version ES"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume 1"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume1-2.pdf"])}
    },
    "volume1_2022_en": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version EN"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume 1"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volume1-2-EN.pdf"])}
    },
    "volume2_2023": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version ES"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume 2"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volumen2-2022.pdf"])}
    },
    "volume2_2023_en": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version EN"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume 2"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/volumen2-2022-EN.pdf"])}
    },
    "breaking": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 28, 2022"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breaking the<br/>paradigms of<br/>expression: Language<br/>& New Beauty"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/breaking.pdf"])}
    },
    "luxuryGaming": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September 13, 2021"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury gaming:<br/>e-gaming & metaverse"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/luxury-gaming.pdf"])}
    },
    "theFutureOfLuxuryBrands": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neo Magazine ", "|", " January 28, 2022"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Future of Luxury<br/>Brands and the<br/>Metaverse World"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/the-future-of-luxury-brands.pdf"])}
    },
    "luxuryBrands": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Financiero ", "|", " June 21, 2022"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury brands, the new<br/>players in the<br/>egames court"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/luxury-brands.pdf"])}
    },
    "elMundo": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazine ", "|", " October 23, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL MUNDO DESDE DIFERENTES PERSPECTIVAS"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/el-mundo-desde-diferentes-perspectivas.pdf"])}
    },
    "badHombre": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BADHOMBRE MAGAZINE"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://badhombremagazine.com/?s=trends+lab"])}
    },
    "shockvertising": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 25, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shockvertising:<br/>Is all advertising good<br/>advertising?"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/shockvertising.pdf"])}
    },
    "emotions": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 25, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers, emotions and behaviours"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/emotions.pdf"])}
    },
    "artificialIntelligence": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 25, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artificial Intelligence: the solution to the creative crisis?"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/artificial-intelligence.pdf"])}
    },
    "shortage": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortage: the new trend to create hype"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/shortage.pdf"])}
    },
    "emotionsAi": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emotions: the fuel for artificial intelligence"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/emotions-ai.pdf"])}
    },
    "goldenAgeOfSports": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March, 2023"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The golden age of sports?"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/pdf/golden-age-of-sports.pdf"])}
    },
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])}
  },
  "footer": {
    "stayConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay connected"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "mainOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main office"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C. Artículo 123 Nº 116, dept 104, Col. Centro, 06040 Mexico City."])},
    "hear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to hear something different?"])},
    "followUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us"])},
    "allRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])}
  },
  "about": {
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "theFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-extrabold\">Trends Lab</span> is the first trend lab in Mexico, offering a form of fact-based inspiration."])},
    "atTrendsLab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Trends Lab we develop innovation from <span class=\"font-normal\">researching trends about to unfold</span>, new changing agents that will be transformed into <span class=\"font-normal\">disruptive concepts and ideas to drive business opportunity</span> and competitive advantage. We are a multidisciplinary laboratory that develops trends and innovation."])},
    "getInTouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
    "trendsTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TREND'S TEAM"])},
    "ceoFounder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO & FOUNDER"])},
    "chiefIntelligence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHIEF INTELLIGENCE OFFICER"])},
    "designMaterialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESIGN & MATERIALIZATION"])},
    "artDirector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ART DIRECTOR"])},
    "publicRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PUBLIC RELATIONS"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COPYWRITING & PROOFREADING"])},
    "sociologist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOCIOLOGIST"])},
    "fashionTrends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FASHION TRENDS"])}
  },
  "services": {
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "digestReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digest report"])},
    "provide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide a source of inspiration and information for <span class=\"font-medium\">the creation of market strategies, as well as the understanding of future habits.</span>"])},
    "trendsForecasting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trend Forecasting"])},
    "consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting and<br/>custom reports:"])},
    "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Trends + Concepts) * Ideas =<br/><span class=\"font-bold\">Business opportunity.</span>"])},
    "theObjective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The objective is detecting <br class=\"hidden md:block\"/>new trends and new <br class=\"hidden md:block\"/>user groups for brands and <br class=\"hidden md:block\"/>applying them to <span class=\"font-semibold\">business <br class=\"hidden md:block\"/>models to create <br class=\"hidden md:block\"/>competitive advantage <br class=\"hidden md:block\"/>and innovation.</span>"])},
    "weWill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will work together to <br class=\"hidden md:block\"/>identify your next growth <br class=\"hidden md:block\"/>opportunity by leveraging <br class=\"hidden md:block\"/>our <span class=\"font-semibold\">five labs and our multi- <br/>disciplinary team.</span>"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-extrabold\">Check out</span> the most current trends."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
  },
  "contact": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "mainOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Office"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C. Artículo 123 Nº 116, dept 104, Col. Centro, 06040 Mexico City."])}
  }
}